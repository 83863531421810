<template>
  <pas-fullscreen-modal @close="onClose" :isOpen="isOpen">
    <v-container class="modal-container">
      <v-layout>
        <v-flex xs12 text-xs-center>
          <pas-page-title>Define the roles</pas-page-title>
        </v-flex>
      </v-layout>
      <v-layout column>
        <v-flex xs12 mt-4 text-xs-center>
          <span>
            Define roles and responsibilities related to this {{ (clientTypeText || 'client').toLowerCase() }}.
            If a person or Legal Entity is not in Advisor Center, you can
            <a
              href="#"
              @click="openNewClientModal()"
              class="action-text underline"
              id="add-client-from-define-roles-page"
            >
              add a new client
            </a>.
            <br><br>
          </span>
        </v-flex>
        <span class="mb-3">{{ instructionsText }}</span>
        <template v-if="definedCorporateRoles.length">
          <v-card class="px-3 py-3 my-3" elevation="3">
            <div class="strong-text mb-3 mt-3">Defined roles</div>
            <v-layout
              v-for="(cr, index) in definedCorporateRoles"
              :key="cr.id"
              mb-2
              row
              align-center
            >
              <v-flex xs6 md2>
                <v-layout align-center>
                  <v-btn
                    icon
                    x-small
                    v-if="editable"
                    @click="removeDefinedCorporateRole(cr.id, index)"
                  >
                    <font-awesome-icon :icon="['fal', 'xmark']" />
                  </v-btn>
                  <span>{{ clientList[cr.client] }}</span>
                </v-layout>
              </v-flex>
              <v-flex xs6 md10 class="pl-2">
                <span>{{ getRoleSummary(cr, { onlyRoles: false }) }}</span>
              </v-flex>
            </v-layout>
          </v-card>
        </template>
        <v-form v-if="editable" ref="form" lazy-validation @submit.prevent="">
          <v-layout mt-3 column>
            <v-card v-for="(item, i) in corporateRoles" :key="i" class="px-3 py-3 my-3" elevation="3">
              <div class="strong-text mb-3">
                <span>New role</span>
                <v-btn icon x-small @click="corporateRoles.splice(i, 1)">
                  <font-awesome-icon :icon="['fal', 'xmark']" />
                </v-btn>
              </div>
              <div class="strong-text mb-1">Search client list</div>
              <v-layout row>
                <v-flex xs12 md6>
                  <v-layout align-center class="pb-3">
                    <pas-autocomplete
                      :placeholder="`Type here to search...`"
                      :prependInnerIcon="`search`"
                      :key="`corporate-roles-autocomplete`"
                      :items="filteredClientList[i]"
                      v-model="item.client"
                      :return-object="true"
                      @change="clientChange(item)"
                    />
                  </v-layout>
                </v-flex>
              </v-layout>
              <fieldset v-if="item.client">
                <v-flex mt-4>
                  <legend class="strong-text">Type of role</legend>
                </v-flex>
                <v-layout row wrap>
                  <v-flex xs3 v-for="(role, j) in roles(item)" :key="j">
                    <v-checkbox
                      hide-details
                      v-model="item[role.name]"
                      @change="roleChange(item)"
                      class="black-label py-2"
                      :color="primaryColor"
                      :label="role.label"
                      :disabled="role.disabled && role.disabled(item)"
                    />
                  </v-flex>
                </v-layout>
              </fieldset>
              <div class="mt-4" v-if="isAnyRoleSelected(item)">
                <div class="mt-3 mb-4" v-if="isOtherDescriptionVisible(item)">
                  <v-flex mt-2 mb-3>
                    <label for="other_description" class="strong-text">
                      (Optional) Please specify the other role
                    </label>
                  </v-flex>
                  <v-flex xs4>
                    <pas-input-field
                      id="other_description"
                      v-model="item.other_description"
                      :label="'Other role'"
                    />
                  </v-flex>
                </div>
                <div v-if="isAuthorizedSignatoryVisible(item)">
                  <v-flex mt-2>
                    <label for="authorized_signatory" class="strong-text">
                      Is this person an Authorized Signatory?
                    </label>
                  </v-flex>
                  <v-flex>
                    <v-switch
                      id="authorized_signatory"
                      class="black-label"
                      :color="primaryColor"
                      v-model="item.authorized_signatory"
                      :label="item.authorized_signatory ? 'Yes' : 'No'"
                      @change="authorizedSignatoryChange(item)"
                      :disabled="item.authorizedSignatoryDisabled"
                    />
                  </v-flex>
                </div>
                <div v-if="isAuthorizedSignatoryVisible(item) && isPreferredSignorVisible(item)">
                  <v-flex mt-2>
                    <label for="preferred_or_mandatory_signor" class="strong-text">
                      Is this person a preferred and/or mandatory signor?
                    </label>
                  </v-flex>
                  <v-flex>
                    <v-switch
                      id="preferred_or_mandatory_signor"
                      class="black-label"
                      :color="primaryColor"
                      v-model="item.preferred_or_mandatory_signor"
                      :label="item.preferred_or_mandatory_signor ? 'Yes' : 'No'"
                    />
                  </v-flex>
                </div>
                <div v-if="isPercentageOwnershipVisible(item)">
                  <v-flex mt-2>
                    <label for="percentage_ownership" class="strong-text">Percentage ownership</label>
                  </v-flex>
                  <v-flex class="mt-2 mb-4">
                    <div :style="{ 'max-width': '135px' }">
                      <pas-number-input
                        id="percentage_ownership"
                        name="percentage_ownership"
                        min="0"
                        step="0.1"
                        suffix="%"
                        v-model="item.percentage_ownership"
                        @upClick="adjustPercentageOwnership(item, 1)"
                        @downClick="adjustPercentageOwnership(item, -1)"
                      />
                    </div>
                  </v-flex>
                </div>
              </div>
              <v-divider class="mt-2" v-if="item.client" />
              <v-flex mt-2 v-if="item.client">
                <span class="strong-text">Summary:</span>
                <span>{{ getRoleSummary(sanitizeItem(item), { onlyRoles: false }) }}</span>
              </v-flex>
            </v-card>
            <v-flex mt-4>
              <pas-add-item :text="'Add Role'" @click="add" id="add-additional-corp-role-btn" />
            </v-flex>
          </v-layout>
        </v-form>
      </v-layout>
      <v-layout>
        <v-flex text-xs-center xs12>
          <pas-error-div :show="!!errorText">{{ errorText }}</pas-error-div>
        </v-flex>
      </v-layout>
      <v-layout mt-5 justify-end>
        <pas-button
          class="px-5"
          :disabled="disabledRoles"
          @click="editable ? save() : $emit('close')"
          id="save-corporate-roles-btn"
        >
          {{ editable ? 'Save' : 'OK' }}
        </pas-button>
      </v-layout>
    </v-container>
    <add-new-client
      :isOpen="newClientModalOpen && !hasFeatureFlag('client_data_hierarchy')"
      @close="newClientModalOpen = false"
    />
    <AddNewClientNoCall
      :isOpen="newClientModalOpen && hasFeatureFlag('client_data_hierarchy')"
      @close="newClientModalOpen = false"
    />
    <MessageDialog @ok="CDHIneligibleDialog = false" :isOpen="CDHIneligibleDialog" :width="'40%'">
      <template v-slot:header>Cannot add clients</template>
      {{ cantAddClientsMsg }}
      <template v-slot:btn>Okay</template>
    </MessageDialog>
  </pas-fullscreen-modal>
</template>

<script>
/* eslint-disable implicit-arrow-linebreak */
import AddNewClient from 'Components/Modals/AddNewClient';
import { WARNING_MESSAGES } from 'Services/constants';
import AddNewClientNoCall from 'Components/Modals/AddNewClientNoCall';
import { modal } from 'Services/mixins';
import MessageDialog from 'Components/Shared/MessageDialog';
import { mapGetters } from 'vuex';
import { percentage } from 'Services/filters';
import {
  getRoleSummary,
  LEGAL_ENTITY_INSTRUCTIONS,
  LEGAL_ENTITY_TYPES,
  ROLE_CONFIG,
  ROLES,
} from 'Services/helpers/corporateRoles';
import { formatNumberToDecimal } from '../../services/helpers/number';

export default {
  name: 'define-roles',
  components: {
    AddNewClient,
    AddNewClientNoCall,
    MessageDialog,
  },
  filters: {
    percentage,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [modal],
  data() {
    return {
      newClientModalOpen: false,
      CDHIneligibleDialog: false,
      cantAddClientsMsg: WARNING_MESSAGES.cannot_add_clients,
      corporateRoles: [],
      definedCorporateRoles: [],
      deletedRoles: [],
      disabledRoles: false,
      role: null,
      errorText: '',
      getRoleSummary,
      ROLES,
    };
  },
  mounted() {
    if (!this.corporateRoles.length) this.add();
  },
  computed: {
    ...mapGetters([
      'hasFeatureFlag',
      'currentUser',
      'advisorTeams',
      'canEditCorporateClientRelationships',
    ]),
    primaryColor() {
      return this.$store.state.app.themeVars.primaryColor;
    },
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    clients() {
      if (this.$store.state.clients.list[0] === 'loading') return ['loading'];
      return this.$store.state.clients.list
        .filter((client) => {
          const definedRolesAndCurrentClient = [
            this.activeClient.client.id,
            ...this.definedCorporateRoles.map((r) => r.client),
          ];
          return !definedRolesAndCurrentClient.includes(client.id);
        })
        .map((client) => {
          // eslint-disable-next-line camelcase
          const { id, display_name: displayName, client_type } = client;
          // eslint-disable-next-line camelcase
          return { text: displayName || '', value: id, client_type };
        });
    },
    newRolesClientIds() {
      return this.corporateRoles.filter((r) => r.client).map((r) => r.client.value);
    },
    filteredClientList() {
      // one list of client options for each new role
      return this.corporateRoles.map((role) =>
        this.clients.filter((client) =>
        // exclude the current client and any client already assigned to a role
          !this.newRolesClientIds.includes(client.value) || role.client?.value === client?.value));
    },
    corporateClients() {
      const corporateClients = [];
      this.$store.state.clients.list
        .filter((client) => client.client_type && client.client_type !== 'person')
        .forEach((client) => corporateClients.push(client.id));
      return corporateClients;
    },
    clientList() {
      const clientList = {};
      this.$store.state.clients.list
        .forEach((client) => {
          const { id, display_name: displayName } = client;
          clientList[id] = displayName;
        });
      return clientList;
    },
    editable() {
      return this.canEditCorporateClientRelationships;
    },
    clientTypeText() {
      return LEGAL_ENTITY_TYPES[this.activeClient?.client?.client_type];
    },
    instructionsText() {
      return LEGAL_ENTITY_INSTRUCTIONS[this.activeClient?.client?.client_type];
    },
  },
  methods: {
    roles(corporateRole) {
      const currentClientType = this.activeClient?.client?.client_type?.toUpperCase();
      const addedClientType = corporateRole?.client?.client_type === 'person' ? 'INDIVIDUAL' : 'LEGAL_ENTITY';
      if (!currentClientType || !addedClientType) return [];
      // Only display for individual. Will have to let each row decide
      return ROLE_CONFIG[currentClientType]?.[addedClientType] || [];
    },
    isPercentageOwnershipVisible(corporateRole) {
      // should be checking the corporateRole client_type not my client type
      const currentClientType = this.activeClient?.client?.client_type?.toUpperCase();
      const addedClientType = corporateRole?.client?.client_type === 'person' ? 'INDIVIDUAL' : 'LEGAL_ENTITY';
      if (!currentClientType || !addedClientType) return false;

      // Only display for individual. Will have to update for le/individual
      const rolesAskingForPercentage = ROLE_CONFIG[currentClientType]?.[addedClientType].filter((role) => role.askForPercentageOwnership);
      if (!rolesAskingForPercentage.length) return false;

      // Check if the roles selected for this CorporateRole should ask for PercentageOwnership
      let matchingRole = false;
      rolesAskingForPercentage.forEach((role) => {
        matchingRole = corporateRole?.[role.name];
      });
      if (matchingRole) return true;

      return false;
    },
    isOtherDescriptionVisible(corporateRole) {
      return corporateRole.other;
    },
    authorizedSignatoryChange(corporateRole) {
      if (!corporateRole.authorized_signatory) {
        corporateRole.preferred_or_mandatory_signor = false;
      }
    },
    isPreferredSignorVisible(corporateRole) {
      return !!corporateRole.authorized_signatory;
    },
    isAuthorizedSignatoryVisible(corporateRole) {
      // overrideMatchingRole is used if we have a conflict
      // ex. one role doesn't need authorized_signatory and one does
      let matchingRole = true;
      let overrideMatchingRole = false;

      this.roles(corporateRole).forEach((role) => {
        if (corporateRole?.[role.name] && role.skipAuthorizedSignatory) {
          matchingRole = false;
        } else if (corporateRole?.[role.name] && !role.skipAuthorizedSignatory) {
          overrideMatchingRole = true;
        }
      });

      return overrideMatchingRole || matchingRole;
    },
    isAnyRoleSelected(corporateRole) {
      const currentClientType = this.activeClient?.client?.client_type?.toUpperCase();
      const addedClientType = corporateRole?.client?.client_type === 'person' ? 'INDIVIDUAL' : 'LEGAL_ENTITY';

      return ROLE_CONFIG[currentClientType]?.[addedClientType]
        .some((role) => corporateRole?.[role.name]);
    },
    hasRoleThatEnablesAuthorizedSignatory(corporateRole) {
      const rolesThatSkipSignor = Object.values(ROLES)
        .filter((role) => !role.skipAuthorizedSignatory);
      return rolesThatSkipSignor.some((role) => corporateRole[role.name]);
    },
    roleChange(item) {
      if (item.beneficial_owner) {
        item.percentage_ownership = item.percentage_ownership ?? '0.00';
      } else {
        item.percentage_ownership = null;
      }

      if (item.secretary) {
        item.authorized_signatory = true;
        item.authorizedSignatoryDisabled = true;
      } else {
        item.authorizedSignatoryDisabled = false;
      }

      if (!this.hasRoleThatEnablesAuthorizedSignatory(item)) {
        item.authorized_signatory = false;
        item.preferred_or_mandatory_signor = false;
      }

      if (!item.other) {
        item.other_description = null;
      }

      if (!this.isAnyRoleSelected(item)) {
        // Reset non-role values
        item.authorized_signatory = false;
        item.preferred_or_mandatory_signor = false;
        item.percentage_ownership = null;
      }
    },
    clientChange(corporateRole) {
      // Reset all values if client is changed
      const currentClientType = this.activeClient?.client?.client_type?.toUpperCase();
      ROLE_CONFIG[currentClientType]?.INDIVIDUAL.forEach((role) => {
        corporateRole[role.name] = false;
      });
      ROLE_CONFIG[currentClientType]?.LEGAL_ENTITY.forEach((role) => {
        corporateRole[role.name] = false;
      });

      corporateRole.percentage_ownership = null;
      corporateRole.preferred_or_mandatory_signor = false;
      corporateRole.authorized_signatory = false;
    },
    openNewClientModal() {
      if (this.hasFeatureFlag('client_data_hierarchy') && !this.advisorTeams.length) {
        this.CDHIneligibleDialog = true;
        return;
      }
      this.newClientModalOpen = true;
    },
    validate() {
      this.errorText = '';
      const newClientIDs = this.newRolesClientIds;
      const oldClientIDs = this.definedCorporateRoles.map((r) => r.client);
      const commonClient = newClientIDs.filter((r) => oldClientIDs.includes(r));
      const cr = this.corporateRoles.map((r) => formatNumberToDecimal(Number(r.percentage_ownership)));
      const dcr = this.definedCorporateRoles.map((r) => formatNumberToDecimal(Number(r.percentage_ownership) * 100));

      const ownershipPercentage = this.corporateRoles.map((r) => formatNumberToDecimal(Number(r.percentage_ownership)))
        .concat(this.definedCorporateRoles.map((r) => formatNumberToDecimal(Number(r.percentage_ownership) * 100)))
        .reduce((a, b) => a + b, 0);
      const rolesAssigned = this.corporateRoles.filter((r) => r.trading_authority || r.secretary || r.director || r.guarantor || r.president || r.vice_president || r.beneficial_owner || r.settlor || r.trustee || r.beneficiary || r.sponsoring_firm_trustee || r.other);
      const corporateRoleCheck = this.corporateRoles.filter((r) => this.corporateClients.includes(r.client)
        && (r.trading_authority || r.secretary || r.director || r.president || r.vice_president));

      if (rolesAssigned.length !== newClientIDs.length) {
        this.errorText = 'You must assign a role to all clients';
        return false;
      }
      if (newClientIDs.length !== (new Set(newClientIDs)).size) {
        this.errorText = 'Please remove duplicate beneficial owner';
        return false;
      }
      if (commonClient.length) {
        this.errorText = `${this.clientList[commonClient[0]]} has already been assigned a role`;
        return false;
      }
      if (ownershipPercentage > 100) {
        this.errorText = 'Total ownership percentage cannot exceed 100%';
        return false;
      }
      if (corporateRoleCheck.length) {
        this.errorText = 'Corporate clients can only be assigned the role of a beneficiary owner or a guarantor';
        return false;
      }
      return true;
    },
    save() {
      if (!this.validate()) {
        return;
      }
      this.disabledRoles = true;
      const formattedRoles = this.corporateRoles
        .filter((rel) => rel?.client?.value)
        .map((rel) => {
          const role = {
            corporate_client: this.activeClient.client.id,
            percentage_ownership: rel.percentage_ownership === null
              ? rel.percentage_ownership
              : formatNumberToDecimal(Number(rel.percentage_ownership) / 100, 4),
            client: rel.client.value,
            authorized_signatory: rel.authorized_signatory,
            beneficial_owner: rel.beneficial_owner,
            beneficiary: rel.beneficiary,
            director: rel.director,
            guarantor: rel.guarantor,
            other: rel.other,
            other_description: rel.other_description,
            preferred_or_mandatory_signor: rel.preferred_or_mandatory_signor,
            president: rel.president,
            secretary: rel.secretary,
            settlor: rel.settlor,
            sponsoring_firm_trustee: rel.sponsoring_firm_trustee,
            trading_authority: rel.trading_authority,
            trustee: rel.trustee,
            vice_president: rel.vice_president,
          };
          return role;
        });
      Promise.all(
        formattedRoles.map((data) => this.$store.dispatch('addCorporateRole', data))
          .concat(this.deletedRoles.map((r) => this.$store.dispatch('deleteCorporateRole', r))),
      ).then((responses) => {
        const goodRes = responses.filter((res) => res.id || res === true);
        this.handleResponse(goodRes.length === responses.length ? 'success' : 'fail');
      })
        .catch((err) => {
          console.error(err);
          this.handleResponse('fail');
        });
    },
    add() {
      this.corporateRoles.push({
        client: null,
        percentage_ownership: null,
        authorized_signatory: false,
        beneficial_owner: false,
        beneficiary: false,
        director: false,
        guarantor: false,
        other: false,
        other_description: null,
        preferred_or_mandatory_signor: false,
        president: false,
        secretary: false,
        settlor: false,
        sponsoring_firm_trustee: false,
        trading_authority: false,
        trustee: false,
        vice_president: false,
      });
    },
    removeDefinedCorporateRole(id, i) {
      this.definedCorporateRoles.splice(i, 1);
      this.deletedRoles.push(id);
    },
    handleResponse(type) {
      this.disabledRoles = false;
      this.deletedRoles = [];
      const text = type === 'fail' ? 'Failed to assign the role' : 'Role(s) successfully updated';
      this.$store.dispatch('setSnackbar', { text, type });
      this.$store.dispatch('flipSnackbarSwitch');
      this.corporateRoles = [];
      this.add();
      this.$emit('save');
    },
    sanitizeItem(item) {
      return {
        ...item,
        percentage_ownership: item.percentage_ownership / 100,
      };
    },
    adjustPercentageOwnership(item, incrementAmount) {
      const currentPercentage = Number(item.percentage_ownership);
      const result = currentPercentage + incrementAmount;
      if (result > 100) {
        item.percentage_ownership = 100;
      } else if (result < 0) {
        item.percentage_ownership = 0;
      } else {
        item.percentage_ownership = Number.parseFloat(result).toFixed(2);
      }
    },
    onClose() {
      this.corporateRoles = [];
      this.deletedRoles = [];
      this.add();
      this.$emit('close');
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.definedCorporateRoles = this.activeClient.corporate_roles
          .filter((cr) => this.activeClient.client.id === cr.corporate_client);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  fieldset {
    border: unset;
    padding: unset;
  }
.black-label:deep {
  label {
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>
